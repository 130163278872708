
import { BASE_URL } from "./Authservice";

export const SERVICE = {
  //App

  // Companies
  COMPANY: `${BASE_URL}/api/companies`,
  COMPANYLIMIT: `${BASE_URL}/api/companieslimit`,
  COMPANY_CREATE: `${BASE_URL}/api/company/new`,
  COMPANY_SINGLE: `${BASE_URL}/api/company`,
  OVERALL_COMPANY: `${BASE_URL}/api/getoverallcompany`,
  COMPANY_OVERALLDELETE: `${BASE_URL}/api/companydelete`,
  BRANCHNAMECHECK: `${BASE_URL}/api/checkbranch`,
  USERCHECK: `${BASE_URL}/api/checkuser`,
  USERSHIFTCHECK: `${BASE_URL}/api/user/shiftcheck`,
  ROLENAME: `${BASE_URL}/api/rolesname`,

  ALL_COORDINATOR: `${BASE_URL}/api/allcoordinators`,
  CREATE_COORDINATOR: `${BASE_URL}/api/createcoordinator`,
  SINGLE_COORDINATOR: `${BASE_URL}/api/singlecoordinator`,




  //USER
  USER: `${BASE_URL}/api/users`,
  ALLUSERSDATA: `${BASE_URL}/api/getallusersdata`,
  USER_CREATE: `${BASE_URL}/api/auth/new`,
  USER_SINGLE: `${BASE_URL}/api/auth`,
  USERALLLIMIT: `${BASE_URL}/api/usersalllimit`,
  USER_SINGLE_PWD: `${BASE_URL}/api/userpw`,
  USERSEXCELDATA: `${BASE_URL}/api/usersexceldata`,
  USERTASKPROFILE: `${BASE_URL}/api/usertaskprofile`,
  SENDMAIL: `${BASE_URL}/send-email`,
  USER_STATUS: `${BASE_URL}/api/usersstatus`,
  USERS_LOGIN: `${BASE_URL}/api/usersloginallot`,
  USER_SHIFTALLOT_UPDATE: `${BASE_URL}/api/usershiftallotsupdate`,
  USER_SHIFTALLOT_UPDATE_STATUS: `${BASE_URL}/api/usershiftallotsupdatestatus`,
  USER_CLOCKIN_CLOCKOUT_STATUS_FOR_MONTH_LOP_CAL: `${BASE_URL}/api/userclockinclockoutstatusformontlopcal`,
  USER_CLOCKIN_CLOCKOUT_STATUS_FOR_MONTH_LOP_CAL_FILTER: `${BASE_URL}/api/userclockinclockoutstatusformontlopcalfilter`,
  USERSENQUIERY: `${BASE_URL}/api/usersenquirystatus`,
  ALLUSER: `${BASE_URL}/api/allusers`,
  USER_CLOCKIN_CLOCKOUT_STATUS: `${BASE_URL}/api/userclockinclockoutstatus`,
  USER_CLOCKIN_CLOCKOUT_STATUS_FILTER: `${BASE_URL}/api/userclockinclockoutstatusfilter`,
  USER_CLOCKIN_CLOCKOUT_STATUS_INDVL: `${BASE_URL}/api/userclockinclockoutstatusindvl`,
  USER_CLOCKIN_CLOCKOUT_STATUS_INDVL_FILTER: `${BASE_URL}/api/userclockinclockoutstatusindvlfilter`,
  USER_CLOCKIN_CLOCKOUT_STATUS_INDVL_HIERARFILTER: `${BASE_URL}/api/userclockinclockoutstatusindvlhierarchyfilter`,
  USER_CLOCKIN_CLOCKOUT_STATUS_MYINDVL: `${BASE_URL}/api/userclockinclockoutstatusmyindvl`,
  SHIFTUSERSTIMINGFILTER: `${BASE_URL}/api/shiftuserstimingfilter`,
  UPDATE_ATTANDANCESTATUS: `${BASE_URL}/api/updatesingleattendanceatatus`,


  //EXCEL
  EXCEL: `${BASE_URL}/api/excels`,
  EXCELFILTERED: `${BASE_URL}/api/excelsfiltered`,
  EXCEL_CREATE: `${BASE_URL}/api/excel/new`,
  EXCEL_SINGLE: `${BASE_URL}/api/excel`,

  //EXCELmapdata
  EXCELMAPDATA: `${BASE_URL}/api/excelmapdatas`,
  EXCELMAPDATA_CREATE: `${BASE_URL}/api/excelmapdata/new`,
  EXCELMAPDATA_SINGLE: `${BASE_URL}/api/excelmapdata`,
  EXCELMAPDATAQUEUECHECK: `${BASE_URL}/api/timpoints/queue`,
  EXCELMAPCATEGORY: `${BASE_URL}/api/categoryexcel`,
  QUEUEMAPCATEGORY: `${BASE_URL}/api/queueexcel`,
  CUSTOMEREXCEL: `${BASE_URL}/api/customerexcel`,
  EXCELMAPDATARESPERSON_INDIVIDUALWORKORDER: `${BASE_URL}/api/individualworkorderlist`,
  EXCELMAP_MAPPERSON_DELETE: `${BASE_URL}/api/excelmapandpersondelete`,
  GETOVERALL_EXCELMAPDATA: `${BASE_URL}/api/getoverallallottedqueue`,
  EXCELMAPDATAFILTERED: `${BASE_URL}/api/excelmapfiltered`,
  EXCELUNALLOTEDFILTERED: `${BASE_URL}/api/unallotedexcelqueuelist`,
  ALLOTTED_QUEUE_LIST_FILTER: `${BASE_URL}/api/allottedqueuelistfilter`,
  EXCELUNALLOTEDFILTERED_OVERALL: `${BASE_URL}/api/unallotedexcelqueuelistOverall`,

  //EXCELmapdata
  EXCELMAPDATARESPERSON: `${BASE_URL}/api/excelmaprespersondatas`,
  EXCELMAPDATARESPERSON_CREATE: `${BASE_URL}/api/excelmaprespersondata/new`,
  EXCELMAPDATARESPERSON_SINGLE: `${BASE_URL}/api/excelmaprespersondata`,
  EXCELMAPDATARESPERSON_TEAMTOTAL: `${BASE_URL}/api/excelteamtotal`,
  EXCELMAPDATARESPERSON_BRANCHTOTAL: `${BASE_URL}/api/excelbranchtotal`,
  EXCELMAPDATARESPERSON_RESPERSONTOTAL: `${BASE_URL}/api/excelrespersontotal`,
  EXCELUNALLOTEDRESPERSONFILTERED: `${BASE_URL}/api/unallotedexcelrespersonlist`,
  ALLOTTED_RESPONSIBLE_QUEUE_LIST_FILTER: `${BASE_URL}/api/allottedresponsiblequeuelist`,


  //LOGIN OUT STATUS
  LOGINOUT: `${BASE_URL}/api/attandances`,
  LOGINOUT_CREATE: `${BASE_URL}/api/attandance/new`,
  LOGINOUT_SINGLE: `${BASE_URL}/api/attandance`,
  ATTENDANCE_FILTER: `${BASE_URL}/api/attendancefilter`,
  ATTENDANCE_COLUMN_FILTER: `${BASE_URL}/api/attendancecolumnfilter`,
  ATTANDANCE_STATUS_DATE: `${BASE_URL}/api/attandancesstatusdates`,
  LOGINOUT_USERID: `${BASE_URL}/api/attandanceid`,
  ATTANDANCE_SINGLE: `${BASE_URL}/api/attandance`,
  USERFILTER: `${BASE_URL}/api/attendfilter`,
  ATTANDANCE_STATUS_USERDATE: `${BASE_URL}/api/attandancesstatususerdates`,
  ATTANDANCE_STATUS: `${BASE_URL}/api/attandancesstatus`,
  LOGINOUT_STATUSFALSE: `${BASE_URL}/api/attandancefalse`,
  LOGINOUT_STATUSTRUE: `${BASE_URL}/api/attandancetrue`,
  ATTENDANCE_HIERARCHYFILTER: `${BASE_URL}/api/attendancehierarchyfilter`,
  ATTENDANCE_HIERARCHYFILTERANOTHER: `${BASE_URL}/api/attendancehierarchyfilteranother`,
  ATTENDANCE_CLOCKIN_CREATE: `${BASE_URL}/api/attandanceclockintimecreate`,
  ATTENDANCE_CLOCKIN_SINGLE: `${BASE_URL}/api/attandanceclockinouttimeedit`,
  OVERALLSORT_ASSET: `${BASE_URL}/api/overallassettablesort`,
  OVERALLSORT_STOCK: `${BASE_URL}/api/stockpurchasesort`,
  //REMARKS
  REMARK_CREATE: `${BASE_URL}/api/remark/new`,
  REMARK: `${BASE_URL}/api/remarks`,

  // roles and responsibilities
  ROLEANDRESPONSE: `${BASE_URL}/api/allroleandresponsibilities`,
  ROLEANDRESPONSE_CREATE: `${BASE_URL}/api/roleandresponsibile/new`,
  ROLEANDRESPONSE_SINGLE: `${BASE_URL}/api/roleandresponsibile`,

  // Documents
  ALL_DOCUMENT: `${BASE_URL}/api/documents`,
  DOCUMENT_CREATE: `${BASE_URL}/api/documents/new`,
  DOCUMENT_SINGLE: `${BASE_URL}/api/document`,

  // List of Document
  LISTOFDOCUMENT: `${BASE_URL}/api/listofdocuments`,
  LISTOFDOCUMENT_CREATE: `${BASE_URL}/api/listofdocument/new`,
  LISTOFDOCUMENT_SINGLE: `${BASE_URL}/api/listofdocument`,

  // Document Grouping
  DOCUMENTGROUPING: `${BASE_URL}/api/documentgroupings`,
  DOCUMENTGROUPING_CREATE: `${BASE_URL}/api/documentgrouping/new`,
  DOCUMENTGROUPING_SINGLE: `${BASE_URL}/api/documentgrouping`,


  //Control Crirteria details
  CONTROLCRITERIA: `${BASE_URL}/api/controlcriterias`,
  CONTROLCRITERIA_CREATE: `${BASE_URL}/api/controlcriteria/new`,
  CONTROLCRITERIA_SINGLE: `${BASE_URL}/api/controlcriteria`,


  // roles and responsibility
  ROLESANDRESPONSECAT: `${BASE_URL}/api/rolesndresponsecategorys`,
  ROLESANDRESPONSECAT_CREATE: `${BASE_URL}/api/rolesndresponsecategory/new`,
  ROLESANDRESPONSECAT_SINGLE: `${BASE_URL}/api/rolesndresponsecategory`,

  // roles of responsibilities
  ROLESANDRES: `${BASE_URL}/api/rolesndresponses`,
  ROLESANDRES_CREATE: `${BASE_URL}/api/rolesndres/new`,
  ROLESANDRES_SINGLE: `${BASE_URL}/api/rolesndres`,

  //EMPLOYEE API
  USERCHECKS: `${BASE_URL}/api/maintenancelog`,
  CREATE_USERCHECKS: `${BASE_URL}/api/maintenancelog/new`,
  USERCHECKS_SINGLE: `${BASE_URL}/api/maintenancelogsingle`,
  SKIPPED_EMPLOYEE: `${BASE_URL}/api/skippedemployee`,
  PRODUCTION_ORGINAL_LIMITED_FILTER: `${BASE_URL}/api/productionoriginalslimitedfilter`,
  CHECK_ISPRODDAY_CREATED: `${BASE_URL}/api/checkisproddaycreated`,
  BULK_DELETE_UNITRATE_UNALLOT: `${BASE_URL}/api/bulkdeleteunitrateunallot`,
  TODAY_HOLIDAY: `${BASE_URL}/api/todayholidayfilter`,
  GETTODAYSHIFT: `${BASE_URL}/api/todayshifts`,
  USERPROJECTCHECK: `${BASE_URL}/api/project/checkuser`,
  USERTTASKCHECK: `${BASE_URL}/api/user/task`,
  USERSWITHSTATUS: `${BASE_URL}/api/userwithstatus`,
  //File Share baseservice
  CREATE_FILESHARE: `${BASE_URL}/api/fileshare/new`,
  ALL_FILESHARE: `${BASE_URL}/api/fileshares`,
  SINGLE_FILESHARE: `${BASE_URL}/api/singlefilshare`,
  //clockinip
  CLOCKINIP: `${BASE_URL}/api/allclockinip`,
  CLOCKINIP_CREATE: `${BASE_URL}/api/clockinip/new`,
  CLOCKINIP_SINGLE: `${BASE_URL}/api/clockinip`,
  BRANCH_IP: `${BASE_URL}/api/getipbybranch`,
  //homepage birthday
  GETUSERDATES: `${BASE_URL}/api/getallusersdates`,
  GET_ALL_DOB: `${BASE_URL}/api/getallusersdob`,
  GET_ALL_DOJ: `${BASE_URL}/api/getallusersdoj`,
  GET_ALL_DOM: `${BASE_URL}/api/getallusersdom`,
  //Atendance Control Criteria
  SINGLE_ATTENDANCE_CONTROL_CRITERIA: `${BASE_URL}/api/singleattendancecontrolcriteria`,
  CREATE_ATTENDANCE_CONTROL_CRITERIA: `${BASE_URL}/api/createattendancecontrolcriteria`,
  GET_ATTENDANCE_CONTROL_CRITERIA: `${BASE_URL}/api/allattendancecontrolcriteria`,
  SINGLE_OVERALL_SETTINGS: `${BASE_URL}/api/singleoverallsettings`,
  CREATE_OVERALL_SETTINGS: `${BASE_URL}/api/createoverallsettings`,
  GET_OVERALL_SETTINGS: `${BASE_URL}/api/getoverallsettings`,
  GETDOCUMENTS: `${BASE_URL}/api/employeedocumentcommonid`,
  GETPROFILES: `${BASE_URL}/api/employeeprofile`,
  SUBCATEGORYPROD_LIST_LIMITED: `${BASE_URL}/api/getlistsubcategoryprodlimited`,

  COMPANYNAME_DUPLICATECHECK_CREATE: `${BASE_URL}/api/checkcompanynamecreate`,

  //ROLE
  ROLE: `${BASE_URL}/api/roles`,
  ROLE_CREATE: `${BASE_URL}/api/role/new`,
  ROLE_SINGLE: `${BASE_URL}/api/role`,
  OVERALL_ROLE: `${BASE_URL}/api/overallrole`,
  ROLEUSERCHECK: `${BASE_URL}/api/user/rolecheck`,
  USER_SINGLE_ROLE: `${BASE_URL}/api/authmultipleroles`,

  //Employee Documents
  EMPLOYEEDOCUMENT: `${BASE_URL}/api/employeedocuments`,
  EMPLOYEEDOCUMENT_CREATE: `${BASE_URL}/api/employeedocuments/new`,
  EMPLOYEEDOCUMENT_SINGLE: `${BASE_URL}/api/employeedocument`,
  EMPLOYEEDOCUMENT_SINGLEWITHALLBYCOMMONID: `${BASE_URL}/api/employeedocumentcommonidwithall`,
  EMPLOYEEDOCUMENTPRE: `${BASE_URL}/api/preemployeedocuments`,

  // Controls Grouping
  CONTROLSGROUPING: `${BASE_URL}/api/controlsgroupings`,
  CONTROLSGROUPING_CREATE: `${BASE_URL}/api/controlsgrouping/new`,
  CONTROLSGROUPING_SINGLE: `${BASE_URL}/api/controlsgrouping`,

  // Manage Department
  MANAGEDEPARTMENT: `${BASE_URL}/api/managedepartments`,
  MANAGEDEPARTMENT_CREATE: `${BASE_URL}/api/managedepartment/new`,
  MANAGEDEPARTMENT_SINGLE: `${BASE_URL}/api/managedepartment`,

  // Manage Designation
  MANAGEDESIGNATION: `${BASE_URL}/api/managedesignations`,
  MANAGEDESIGNATION_CREATE: `${BASE_URL}/api/managedesignation/new`,
  MANAGEDESIGNATION_SINGLE: `${BASE_URL}/api/managedesignation`,

  // Manage Qualifications
  MANAGEQUALIFICATION: `${BASE_URL}/api/managequalifications`,
  MANAGEQUALIFICATION_CREATE: `${BASE_URL}/api/managequalification/new`,
  MANAGEQUALIFICATION_SINGLE: `${BASE_URL}/api/managequalification`,

  // Manage FormGenerate
  MANAGEFORMGENERATE: `${BASE_URL}/api/manageformgenerates`,
  MANAGEFORMGENERATE_CREATE: `${BASE_URL}/api/manageformgenerate/new`,
  MANAGEFORMGENERATE_SINGLE: `${BASE_URL}/api/manageformgenerate`,

  // Manage Qualifications
  MANAGEDOCTORDETAILS: `${BASE_URL}/api/managedoctordetails`,
  MANAGEDOCTORDETAILS_CREATE: `${BASE_URL}/api/managedoctordetail/new`,
  MANAGEDOCTORDETAILS_SINGLE: `${BASE_URL}/api/managedoctordetail`,

  // Hospital Details
  HOSPITALDETAILS: `${BASE_URL}/api/hospitaldetails`,
  HOSPITALDETAILS_CREATE: `${BASE_URL}/api/hospitaldetails/new`,
  HOSPITALDETAILS_SINGLE: `${BASE_URL}/api/hospitaldetails`,


  PATIENTDETAILS: `${BASE_URL}/api/patientdetails`,
  PATIENTDETAILS_CREATE: `${BASE_URL}/api/patientdetails/new`,
  PATIENTDETAILS_SINGLE: `${BASE_URL}/api/patientdetails`,

  MANAGEDOCTORDETAIL_HOSPITALFILTER: `${BASE_URL}/api/managehospitalfilter`,

  //Form Create
  FORM_CREATE: `${BASE_URL}/api/form`,


};
