import React, { useState, useMemo, useEffect, Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
// import Applicationstack from "./routes/Applicationstack";
import { AuthContext, UserRoleAccessContext } from "./context/Appcontext";
// import Authstack from "./routes/Authstack";
import { AUTH } from "./services/Authservice";
import { SERVICE } from "./services/Baseservice";
// import Webstock from "./routes/Webroutes";
import axios from "axios";
import Loading from "./routes/Loading";

const Applicationstack = React.lazy(() => import("./routes/Applicationstack"));
const Authstack = React.lazy(() => import("./routes/Authstack"));

function App() {
  const [auth, setAuth] = useState({
    loader: false,
    loginState: false,
    APIToken: "",
    loginuserid: "",
    loginusercode: "",
  });
  const [isUserRoleAccess, setIsUserRoleAccess] = useState({});
  const [allProjects, setAllprojects] = useState([]);
  const [alltaskLimit, setalltaskLimit] = useState([]);
  const [allTasks, setallTasks] = useState([]);
  const [allUsersLimit, setallUsersLimit] = useState([]);
  const [isUserRoleCompare, setIsUserRoleCompare] = useState([]);
  const [qrImage, setQrImage] = useState("");
  const [isAssignBranch, setIsAssignBranch] = useState([]);
  const [allUsersData, setAllUsersData] = useState([]);
  const [allCompany, setAllCompany] = useState([]);
  const [allBranch, setAllBranch] = useState([]);
  const [allUnit, setAllUnit] = useState([]);
  const [allTeam, setAllTeam] = useState([]);
  const [allfloor, setAllFloor] = useState([]);
  const [allarea, setAllArea] = useState([]);
  const [allareagrouping, setAllAreagrouping] = useState([]);
  const [alllocation, setAllLocation] = useState([]);
  const [alllocationgrouping, setAllLocationgrouping] = useState([]);
  const [alldepartment, setAllDepartment] = useState([]);
  const [alldesignation, setAllDesignation] = useState([]);

  const authContextData = useMemo(() => {
    return { auth, setAuth, qrImage, setQrImage };
  }, [
    auth,
    allProjects,
    isUserRoleCompare,
    alltaskLimit,
    allTasks,
    isAssignBranch,
    isUserRoleAccess,
    allUsersLimit,
    qrImage,
  ]);
  const applicationContextData = useMemo(() => {
    return {
      allUsersData,
      setAllUnit,
      setAllTeam,
      allBranch,
      setAllBranch,
      allTeam,
      setAllCompany,
      setAllUsersData,
      allUnit,
      allCompany,
      isUserRoleAccess,
      setIsUserRoleAccess,
      isAssignBranch,
      setIsAssignBranch,
      isUserRoleCompare,
      setIsUserRoleCompare,
      allProjects,
      setAllprojects,
      allUsersLimit,
      setallUsersLimit,
      alltaskLimit,
      setalltaskLimit,
      allTasks,
      setallTasks,
      allfloor,
      setAllFloor,
      allarea,
      setAllArea,
      allareagrouping,
      setAllAreagrouping,
      alllocation,
      setAllLocation,
      alllocationgrouping,
      setAllLocationgrouping,
      alldepartment,
      setAllDepartment,
      alldesignation,
      setAllDesignation,
    };
  }, [
    auth,
    allUsersData,
    allBranch,
    allTeam,
    allUnit,
    allfloor,
    allCompany,
    allarea,
    allareagrouping,
    alllocation,
    alllocationgrouping,
    alldepartment,
    alldesignation,
    allProjects,
    isUserRoleCompare,
    alltaskLimit,
    allTasks,
    isUserRoleAccess,
    isAssignBranch,
    allUsersLimit,
  ]);

  var today = new Date();
  var todayDate = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
  var yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;

  var todayDateFormat = `${dd}/${mm}/${yyyy}`;

  // Get yesterday's date
  var yesterday = new Date(todayDate);
  yesterday.setDate(todayDate.getDate() - 1);
  var ddp = String(yesterday.getDate()).padStart(2, "0");
  var mmp = String(yesterday.getMonth() + 1).padStart(2, "0"); // January is 0!
  var yyyyp = yesterday.getFullYear();

  var yesterdayDate = yyyyp + "-" + mmp + "-" + ddp;
  var yesterdayDateFormat = `${ddp}/${mmp}/${yyyyp}`;


  useEffect(() => {
    isCheckUserLogin();
  }, []);

  const isCheckUserLogin = async () => {
    setAuth({ ...auth, loader: true });
    let getApiToken = localStorage.getItem("APIToken");
    let getLoginUserid = localStorage.getItem("LoginUserId");
    let getLoginUserRole = localStorage.getItem("LoginUserrole");
    let getLoginUserCode = localStorage.getItem("LoginUsercode");
    const outputArray = getLoginUserRole?.split(",");



    if (getApiToken) {
      try {
        const [
          loginuserdata,
          userrole,
        ] = await Promise.all([
          axios.get(`${AUTH.SINGLE_COORDINATOR}/${getLoginUserid}`, {
            headers: {
              Authorization: `Bearer ${getApiToken}`,
            },
          }),
          axios.post(AUTH.GETAUTHROLE, {
            userrole: outputArray,
          }),

        ]);


        setIsUserRoleCompare(userrole?.data?.result);
        console.log(userrole?.data?.result, "roles");


        setIsUserRoleAccess({
          ...loginuserdata?.data?.singlecoordinator,
          files: [],
          profileimage: "",
          // userdayshift: finalShift,
          // mainshiftname: "",
          // issecondshift: finalShift?.length > 1 ? true : false,
          // secondshiftname: "",
          // secondshifttiming:
          //   finalShift?.length > 1
          //     ? secondshifttimespl[0] + "-" + secondshifttimespl[1]
          //     : "",
          userdayshift: [],
          mainshiftname: "",
          issecondshift: false,
          secondshiftname: "",
          secondshifttiming: "",
          // accessbranch: answer,
        });

        setAllUsersData([]);
        // setAllCompany(allcompany?.data?.companies);
        setAllBranch([]);
        setAllUnit([]);
        setAllTeam([]);
        setAllFloor([]);
        setAllArea([]);
        setAllAreagrouping([]);
        setAllLocation([]);
        setAllLocationgrouping([]);
        setAllDepartment([]);
        setAllDesignation([]);

        setAuth((prevAuth) => {
          return {
            ...prevAuth,
            loader: false,
            loginState: true,
            APIToken: getApiToken,
            loginuserid: getLoginUserid,
            loginuserrole: getLoginUserRole,
            loginusercode: getLoginUserCode,
          };
        });

      } catch (err) {
        console.log(err)
        setAuth({ ...auth, loader: false, loginState: false });
        const messages = err?.response?.data?.message;
        if (messages) {
          console.log(messages);
        } else {
          console.log("Something went wrong check connection!");
        }
      }
    } else {
      setAuth({ ...auth, loader: false, loginState: false });
      console.log("noapi");
    }
  };

  return (
    <>
      {/* <Webstock /> */}
      <div>
        <AuthContext.Provider value={authContextData}>
          <UserRoleAccessContext.Provider value={applicationContextData}>
            <BrowserRouter basename={process.env.PUBLIC_URL}>
              {!auth.loginState ? (
                <Suspense fallback={<Loading />}>
                  <Authstack />
                </Suspense>
              ) : (
                <Suspense fallback={<Loading />}>
                  {" "}
                  <Applicationstack />
                </Suspense>
              )}
            </BrowserRouter>
          </UserRoleAccessContext.Provider>
        </AuthContext.Provider>
      </div>
    </>
  );
}

export default App;
